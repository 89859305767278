var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert-creation"},[_c('b-card',{},[_c('div',{staticClass:"min-card-60"},[(_vm.selectedTab === 'event' && _vm.$route.name === 'alert-create')?_c('AddNewAlert',{attrs:{"getSelectedEvent":_vm.getSelectedEvent,"oldSelectedEvent":_vm.eventData}}):_vm._e(),(_vm.selectedTab === 'configure' && _vm.$route.name === 'alert-create')?_c('AlertConfigure',{attrs:{"eventData":_vm.eventData,"isEditable":true,"alertInfo":_vm.alert,"getVariableConfigInfo":_vm.getVariableConfigInfo}}):_vm._e(),(_vm.selectedTab === 'add-screen')?_c('AlertCreate',{attrs:{"eventData":_vm.eventData,"getUpdatedAlertInfo":_vm.getUpdatedAlertInfo,"alertInfo":_vm.alert,"getVariableConfigInfo":_vm.getVariableConfigInfo,"isViewAlert":_vm.isViewAlert}}):_vm._e()],1),_c('b-row',{staticClass:"mobile-100-col"},[(
          _vm.checkAbility({
            action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
            subject: _vm.constants.PERMISSIONS_MODEL.ALERTS
          }) ||
          _vm.checkAbility({
            action: _vm.constants.PERMISSIONS_ACTION.ADD,
            subject: _vm.constants.PERMISSIONS_MODEL.ALERTS
          })
        )?_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.prevDisabled),expression:"!prevDisabled"}],staticClass:"mr-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.clickPrevBtn}},[_vm._v(_vm._s(_vm.$t("alert.Previous")))]),(_vm.selectedTab !== 'event')?_c('span',[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name !== 'alert-view'),expression:"$route.name !== 'alert-view'"}],attrs:{"variant":"primary","disabled":_vm.nextDisabled},on:{"click":_vm.clickNextBtn}},[_vm._v(_vm._s(_vm.nextBtnTxt))])],1):_vm._e(),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name === 'alert-edit'),expression:"$route.name === 'alert-edit'"}],staticClass:"ml-10",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t("alert.Cancel")))])],1):_vm._e()],1),_c('Loader',{attrs:{"show":_vm.show}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }