<template>
  <div class="alert-creation">
    <b-card class="">
      <!-- <b-button
        variant="outline-primary"
        size="sm"
        v-show="$route.name === 'alert-view'"
        @click="redirectToAlertList"
        class="wizard-back-unit"
      >
        <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
      </b-button> -->
      <div class="min-card-60">
        <AddNewAlert
          v-if="selectedTab === 'event' && $route.name === 'alert-create'"
          :getSelectedEvent="getSelectedEvent"
          :oldSelectedEvent="eventData"
        ></AddNewAlert>

        <AlertConfigure
          v-if="selectedTab === 'configure' && $route.name === 'alert-create'"
          :eventData="eventData"
          :isEditable="true"
          :alertInfo="alert"
          :getVariableConfigInfo="getVariableConfigInfo"
        ></AlertConfigure>
        <!-- <AlertMapConfigure
          v-if="
            selectedTab === 'configure' &&
            isMap &&
            $route.name === 'alert-create'
          "
          :eventData="eventData"
          :isEditable="true"
          :alertInfo="alert"
          :getVariableConfigInfo="getVariableConfigInfo"
        ></AlertMapConfigure> -->

        <AlertCreate
          :eventData="eventData"
          :getUpdatedAlertInfo="getUpdatedAlertInfo"
          :alertInfo="alert"
          :getVariableConfigInfo="getVariableConfigInfo"
          :isViewAlert="isViewAlert"
          v-if="selectedTab === 'add-screen'"
        ></AlertCreate>
      </div>
      <b-row class="mobile-100-col">
        <b-col
          class="d-flex justify-content-end"
          v-if="
            checkAbility({
              action: constants.PERMISSIONS_ACTION.UPDATE,
              subject: constants.PERMISSIONS_MODEL.ALERTS
            }) ||
            checkAbility({
              action: constants.PERMISSIONS_ACTION.ADD,
              subject: constants.PERMISSIONS_MODEL.ALERTS
            })
          "
        >
          <b-button
            class="mr-1"
            v-show="!prevDisabled"
            variant="outline-secondary"
            @click="clickPrevBtn"
            >{{ $t("alert.Previous") }}</b-button
          >
          <span v-if="selectedTab !== 'event'">
            <b-button
              class=""
              variant="primary"
              :disabled="nextDisabled"
              v-show="$route.name !== 'alert-view'"
              @click="clickNextBtn"
              >{{ nextBtnTxt }}</b-button
            >
          </span>
          <b-button
            class="ml-10"
            variant="primary"
            v-show="$route.name === 'alert-edit'"
            @click="$router.go(-1)"
            >{{ $t("alert.Cancel") }}</b-button
          >
        </b-col>
      </b-row>
      <Loader :show="show" />
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  BFormInput,
  VBTooltip,
  BRow,
  BCol
} from "bootstrap-vue";
import AlertCreate from "./AlertCreate.vue";
import AlertConfigure from "./AlertConfigure.vue";
import constants from "@/utils/constants";
import AlertMapConfigure from "./AlertMapConfigure";
import Loader from "@/layouts/components/Loader.vue";
import AddNewAlert from "./AddNewAlert.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UnitService from "@/libs/api/unit-service";
export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    VBTooltip,
    BRow,
    BCol,
    AlertConfigure,
    AlertCreate,
    AddNewAlert,
    AlertMapConfigure,
    Loader
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: [],
  watch: {
    alert(newdata, oldDara) {
      console.log(newdata);
    },
    eventData(val) {
      this.eventData = val;
    },
    selectedTab(val) {
      if (val === "configure") {
        if (
          this.isMap &&
          this.alert.variables &&
          this.alert.variables.length &&
          this.alert.variables[0] &&
          this.alert.variables[0].value &&
          this.alert.variables[0].value.coords
        ) {
          this.nextDisabled = false;
        } else {
          this.nextDisabled = true;
        }
      }
    }
  },
  data() {
    return {
      selectedTab: "event",
      isConfigure: false,
      nextBtnTxt: this.$t("alert.Next"),
      eventData: {},
      nextDisabled: true,
      prevDisabled: true,
      show: false,
      oldAlertData: {},
      isViewAlert: false,
      alert: {
        event_id: "",
        name: "",
        status: true,
        variables: [],
        description: "",
        labels: [],
        allow_child: false
      },
      isMap: false
    };
  },
  mounted() {
    if (this.$route.name !== "alert-create") {
      this.selectedTab = "add-screen";
      if (this.$route.params && this.$route.params.id) {
        this.getAlertById(this.$route.params.id);

        this.nextBtnTxt = this.$t("alert.Save");
      }
      this.prevDisabled = true;
    }
    if (this.$route.name === "alert-create") {
      if (this.$route.query && this.$route.query.id) {
        this.getAlertById(this.$route.query.id);
        this.prevDisabled = true;
      }
    }
  },
  methods: {
    clickNextBtn() {
      if (
        this.selectedTab === "event" &&
        (!this.eventData || !this.eventData.id)
      ) {
        this.nextDisabled = true;
        return false;
      } else {
        this.nextDisabled = false;
      }
      if (this.selectedTab == "add-screen") {
        this.createUpdate();
      }
      if (this.selectedTab === "event" && this.isConfigure) {
        this.selectedTab = "configure";
      } else if (this.selectedTab === "event" && !this.isConfigure) {
        this.selectedTab = "add-screen";
        this.nextBtnTxt = this.$t("alert.CreateAlert");
      } else if (this.selectedTab === "configure" && this.isConfigure) {
        this.selectedTab = "add-screen";
        this.nextBtnTxt = this.$t("alert.CreateAlert");
      }
      if (this.$route.name === "alert-create") {
        this.prevDisabled = false;
      }
    },
    clickPrevBtn() {
      if (this.selectedTab === "add-screen" && this.isConfigure) {
        this.selectedTab = "configure";
        this.nextBtnTxt = this.$t("alert.Next");
        if (
          this.isMap &&
          this.alert.variables &&
          this.alert.variables.length &&
          this.alert.variables[0] &&
          this.alert.variables[0].value &&
          this.alert.variables[0].value.coords
        ) {
          this.nextDisabled = false;
        } else {
          this.nextDisabled = true;
        }
      } else if (this.selectedTab === "add-screen" && !this.isConfigure) {
        this.selectedTab = "event";
        this.nextBtnTxt = this.$t("alert.Next");
      } else if (this.selectedTab === "configure" && this.isConfigure) {
        this.selectedTab = "event";
        this.nextBtnTxt = this.$t("alert.Next");
      }

      if (this.selectedTab === "event") {
        this.prevDisabled = true;
      }
    },
    getSelectedEvent(event) {
      this.eventData = event;
      if (event && event.variables && event.variables.length) {
        this.isConfigure = true;
        // const mapComponent = event.variables.find((e) => e.component == "MAP");
        // this.isMap = mapComponent && mapComponent.component ? true : false;
      } else {
        this.isConfigure = false;
      }
      if (this.eventData && this.eventData.id) {
        this.alert.event_id = this.eventData.id;
      }
      this.clickNextBtn();
    },
    async getUpdatedAlertInfo(refAlert, data) {
      if (this.selectedTab === "add-screen") {
        const validVariable = await refAlert.validate();
        this.nextDisabled = !validVariable;
        if (data) {
          this.alert.name = data.name;
          this.alert.status = data.status;
          this.alert.description = data.description;
          this.alert.labels = data.labels;
          this.alert.allow_child = data.allow_child;
          if (data.name && data.name.length > 2) {
            this.nextDisabled = false;
          } else {
            this.nextDisabled = true;
          }
        }
      }
      if (
        this.isViewAlert &&
        (this.$route.name === "alert-view" || this.$route.name === "alert-edit")
      ) {
        this.nextDisabled = true;
      }
    },
    async getVariableConfigInfo(refVariables, data) {
      if (refVariables) {
        const validVariable = await refVariables.validate();
        this.nextDisabled = !validVariable;
        const alertData = data.filter(
          (e) => e.is_required && (e.value === "" || !e.value.length)
        );
        if (data && data.length >= 1 && alertData && alertData.length >= 1) {
          this.nextDisabled = true;
        } else if (data && data.length >= 1 && data[0].value) {
          this.nextDisabled = false;
        } else {
          this.nextDisabled = true;
        }
      }

      if (data) {
        const variables = data.map((v) => {
          const variablesObj = {
            name: v.name,
            value: v.value
          };
          return variablesObj;
        });
        this.alert.variables = variables;
      }
    },
    async reset() {
      this.alert = JSON.parse(JSON.stringify(this.oldAlertData)) || {};
      this.eventData =
        JSON.parse(JSON.stringify(this.oldAlertData.event)) || {};
      this.eventData.variables = JSON.parse(
        JSON.stringify(this.oldAlertData.variables)
      );
      this.alert.event_id = this.eventData.id;
      this.getSelectedEvent(this.eventData);
    },
    // redirectToAlertList() {
    //   this.$router.push({
    //     name: "alert-list"
    //   });
    // },
    async getAlertById(id) {
      try {
        this.show = true;

        let response = await new UnitService().getAlert({
          id,
          account_id: this.$route.query.account_id
        });
        if (response && response.data) {
          this.show = false;
          this.alert = response.data || {};
          this.oldAlertData = JSON.parse(JSON.stringify(response.data));
          this.eventData = response.data.event;
          this.isViewAlert = !response.data.is_editable;
          this.eventData.variables = response.data.variables;
          this.alert.event_id = this.eventData.id;
          this.$route.meta.breadcrumb = [
            ...constants.ALERTS_ROUTE_META_BREADCRUMB
          ];
          if (this.$route.name === "alert-edit") {
            this.$route.meta.breadcrumb.push(
              ...[
                {
                  to: { name: "alert-view", params: { id: response.data.id } },
                  text: response.data.name
                },
                {
                  text: "Breadcrumb_Edit",
                  active: true
                }
              ]
            );
          } else {
            this.$route.meta.breadcrumb.push({
              text: response.data.name
            });
          }
          if (this.$route.query && this.$route.query.id) {
            this.getSelectedEvent(this.eventData);
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    async createUpdate() {
      if (
        this.isViewAlert &&
        (this.$route.name === "alert-view" || this.$route.name === "alert-edit")
      ) {
        this.nextDisabled = true;
        return;
      }
      try {
        this.show = true;
        const isValidateLabels =
          this.alert.labels &&
          this.alert.labels.every(
            (item) => typeof item === "object" && item !== null
          );

        if (isValidateLabels) {
          // console.log(this.alert.labels);
          const me = this;
          me.alert.labels =
            me.alert &&
            me.alert.labels &&
            me.alert.labels.map((label) => label && label.name);
          me.alert.labels = me.alert.labels.filter(
            (label) => label !== "" || label !== null
          );
        }
        this.alert.labels = this.alert.labels.filter(
          (label) => label !== "" || label !== null
        );

        let id = { id: this.alert.id };
        if (this.$route.query && this.$route.query.id) {
          id = {};
        }
        let response =
          id && id.id
            ? await new UnitService().updateAlert({
                ...id,
                variables: this.alert.variables || [],
                name: this.alert.name,
                status: this.alert.status,
                description: this.alert.description,
                labels: this.alert.labels,
                event_id: this.alert.event_id,
                allow_child: this.alert.allow_child,
                account_id: this.$route.query.account_id
              })
            : await new UnitService().createAlert({
                ...id,
                variables: this.alert.variables || [],
                name: this.alert.name,
                status: this.alert.status,
                description: this.alert.description,
                labels: this.alert.labels,
                event_id: this.alert.event_id,
                allow_child: this.alert.allow_child,
                account_id: this.$route.query.account_id
              });

        if (response && response.data) {
          this.show = false;
          //  this.alert = response.data || {};
          let message = this.$t("alert.AddSuccessfully");
          if (this.alert && this.alert.id) {
            message = this.$t("alert.UpdateSuccessfully");
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
          if (!this.alert || !this.alert.id) {
            this.$router.push({
              name: "notification-create",
              query: { alert_name: this.alert.name }
            });
          } else {
            this.$router.push({ name: "alert-list" });
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  }
};
</script>
<style lang="scss">
.ml-10 {
  margin-left: 10px;
}
.wizard-back-unit {
  position: absolute;
  z-index: 9;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.alert-creation {
  .min-card-60 {
    .under-line-title {
      .card-body {
        // padding: 0px;
        .tab-new-ui {
          .card {
            margin-bottom: 0px;
            .mb-2 {
              margin-bottom: 0px !important;
            }
          }
        }
        .map-outer {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}
</style>
