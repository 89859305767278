<template>
  <b-row>
    <b-col md="12">
      <location-picker
        v-model="inputVal"
        :isEditable="isEditable"
        :types="types"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import LocationPicker from "@/layouts/components/LocationPicker.vue";
export default {
  props: [
    "name",
    "desc",
    "label",
    "data_type",
    "component",
    "value",
    "isEditable",
    "isRequired",
    "component_value"
  ],
  components: { LocationPicker, BRow, BCol },
  data() {
    return {
      inputVal: [],
      isIndeterminate: false,
      types: []
    };
  },
  mounted() {
    this.inputVal = this.value || [];
    if (this.component_value && this.component_value.length) {
      this.types = this.component_value;
    } else {
      this.types = ["polygon", "circle", "rectangle"];
    }
  },
  watch: {
    value(old, newVal) {
      if (this.value && this.inputVal !== this.value) {
        this.inputVal = this.value;
      }
    },
    inputVal: {
      handler(val) {
        this.$emit("input", this.inputVal);
      },
      deep: true
    }
  },
  methods: {
    updateInputState() {
      this.$emit("input", this.inputVal);
    }
  }
};
</script>
<style scoped></style>
