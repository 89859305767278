<template>
  <div
    style="padding-top: 10px"
    class="tab-new-ui"
    v-if="selectedVariables.length"
  >
    <!-- <hr /> -->
    <div :class="$route.name === 'alert-create' ? 'min-card-54' : ''">
      <div class="alert-view mb-2" v-if="$route.name === 'alert-create'">
        <app-collapse>
          <app-collapse-item
            :title="eventData && eventData.name ? eventData.name : ''"
            :isVisible="false"
          >
            <div class="text-primary">
              {{ eventData && eventData.description }}
            </div>
          </app-collapse-item>
        </app-collapse>
      </div>
      <h5>{{ $t("alert.ConfigureRequiredInformation") }}</h5>
      {{ mapLabel }}
      <b-row class="mt-2 mapview p-0">
        <b-col
          lg="6"
          sm="12"
          v-if="selectedVariables && selectedVariables.length"
        >
          <location-picker
            v-model="coordinates"
            :isEditable="isEditable"
            :types="['polygon', 'circle', 'rectangle']"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Map from "@/views/Unit/Map.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { BRow, BCol, BCard, BButton } from "bootstrap-vue";
import LocationPicker from "@/layouts/components/LocationPicker.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    AppCollapse,
    AppCollapseItem,
    Map,
    BButton,
    LocationPicker
  },
  data() {
    return {
      mapCoordinates: null,
      selectedVariables: [],
      isAddPolygon: false,
      mapComponentIndex: -1,
      boundary: null,
      showLoading: false,
      ismapLoad: false,
      items: [],
      mapLabel: "",
      forceLoad: false,
      coordinates: {}
    };
  },
  directives: {},
  watch: {
    eventData(val) {
      this.getMapInfo(val);
      this.forceLoad = !this.forceLoad;
    },
    coordinates: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.setMapCoordinates(newValue);
          this.isValidCoordinates();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getMapInfo(this.eventData);
  },
  props: ["eventData", "getVariableConfigInfo", "alertInfo", "isEditable"],

  methods: {
    getMapInfo(event) {
      this.selectedVariables = (event && event.variables) || [];

      this.items = this.selectedVariables;
      if (this.items && this.items.length) {
        this.mapComponentIndex = this.items.findIndex((prop) => {
          return prop.component == "MAP";
        });
        if (this.mapComponentIndex >= 0) {
          const mapComp = this.items[this.mapComponentIndex];
          this.mapLabel = mapComp.label;
          this.coordinates = {
            ...mapComp,
            fenceType: (mapComp && mapComp.value && mapComp.value.type) || "",
            latlngs:
              (mapComp &&
                mapComp.value &&
                mapComp.value.coords &&
                mapComp.value.coords) ||
              []
          };
          this.mapCoordinates = {
            ...mapComp,
            fenceType: (mapComp && mapComp.value && mapComp.value.type) || "",

            value:
              (mapComp &&
                mapComp.value &&
                mapComp.value.coords && [mapComp.value.coords]) ||
              []
          };
          if (this.mapCoordinates.fenceType === "circle") {
            this.coordinates = {
              ...this.coordinates,
              radius: (mapComp && mapComp.value && mapComp.value.radius) || "",
              center:
                (mapComp &&
                  mapComp.value &&
                  mapComp.value.coords &&
                  mapComp.value.coords) ||
                {}
            };
            this.mapCoordinates = {
              ...this.mapCoordinates,
              radius: (mapComp && mapComp.value && mapComp.value.radius) || "",
              value:
                (mapComp &&
                  mapComp.value &&
                  mapComp.value.coords &&
                  mapComp.value.coords) ||
                {}
            };
          }
        }
      }
      if (this.$refs && this.$refs.refVariables) {
        setTimeout(() => {
          this.getVariableConfigInfo(
            this.$refs.refVariables,
            this.selectedVariables
          );
        }, 100);
      }
    },

    onCloseDeviceSensors() {
      this.onCloseDeviceAddSensors();
    },

    handleError(error) {
      return this.formattedErrorMsg(error);
    },

    setMapCoordinates(p) {
      this.boundary = null;
      let array = [];
      if (p.fenceType == "polygon") {
        array = p.latlngs[0];
      } else if (p.fenceType == "circle") {
        this.boundary = p.center;
      } else if (p.fenceType == "marker") {
        this.boundary = p.latlngs;
      } else if (p.fenceType == "rectangle") {
        array = p.latlngs[0];
      }
      for (let prop of array) {
        if (this.boundary) {
          this.boundary.push([prop.lat, prop.lng]);
        } else {
          let temp = [prop.lat, prop.lng];
          this.boundary = [temp];
        }
      }

      this.mapComponentIndex = this.items.findIndex((prop) => {
        return prop.component == "MAP";
      });
      if (this.mapComponentIndex >= 0) {
        this.items[this.mapComponentIndex]["value"] = this.boundary;
        this.items[this.mapComponentIndex]["fenceType"] = p.fenceType;
        this.selectedVariables[this.mapComponentIndex].value = {
          type: this["items"][this.mapComponentIndex]["fenceType"],
          coords: this.items[this.mapComponentIndex].value
        };
        if (p.fenceType == "circle") {
          this.items[this.mapComponentIndex]["radius"] = p.radius;
          this.selectedVariables[this.mapComponentIndex]["value"]["radius"] =
            p.radius;
        } else {
          delete this.items[this.mapComponentIndex]["radius"];
        }
        this.mapCoordinates = this.items[this.mapComponentIndex];
        this.getVariableConfigInfo(
          this.$refs.refVariables,
          this.selectedVariables
        );
      }
    },
    isValidCoordinates() {
      if (this.mapCoordinates) {
        this.isAddPolygon = false;
      } else {
        this.isAddPolygon = true;
      }
    }
  }
};
</script>
<style lang="scss">
.form-control[readonly] {
  background-color: white;
}
.alert-view {
  .collapse-default .card {
    border: 1px solid var(--primary);
    border-radius: 8px;
    background: var(--rgb-primary-12);
    .collapse-title {
      color: var(--primary);
    }
  }
}
.min-card-54 {
  height: calc(100vh - 250px);
  overflow-y:auto ;
  overflow-x: hidden;
}
.leaflet-draw-draw-polyline {
  display: none !important;
}

@media only screen and (max-width: 820px) {
  .mapview {
    padding: 5px;
  }
}
</style>
